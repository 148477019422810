
const Faq11 = () => (
  <span>
    <p>Supporting UCLA Athletics as a member of our <a target="_blank" rel="noreferrer" href="https://woodenathleticfund.com/">Wooden Athletic Fund</a> qualifies
      you for priority seating benefits for men’s basketball and football games.
      Please note that a contribution in any amount that is required to obtain the right to purchase tickets for these events, or to receive other quid pro quo benefits,
      may not be paid from a donor advised fund and is not considered a charitable contribution for income tax purposes.</p>
    <p>Benefits cannot be provided to a donor whose gifts or pledge payments are paid through a donor advised fund. To receive a benefit offered, an individual
        or entity must personally pay the full amount of the membership level that will provide them with benefits they wish to obtain.</p>
     <p>We appreciate your understanding, as well as your generous support of UCLA Athletics.  If you have any questions or would like to contribute by phone,
        please contact your UCLA Athletics development representative or call 310-206-3302. Thank you and go Bruins!</p>
  </span>
)

export default Faq11

