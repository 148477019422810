// the forms
import Update from './Update'
import Create from './Create'

import css from '../sass/alumni.module.scss'

function AlumniEmail({
  authenticatedUser,
  history
}) {
  const { crmUser } = authenticatedUser

  if (!crmUser) {
    history.push('/help')
    return null
  }
  else if (!crmUser.alumniProfile) {
    history.push('/overview')
    return null
  }

  return crmUser.alumniEmails?.length === 1? (
    // single existing email address
    <section className={css['alumni-wrapper']}>
      <div className={css.content}>
        <h1 className="h2 dark-gray bold with-line line-yellow" id="landingPageTitle">@ALUMNI.UCLA.EDU Email</h1>
        <p>
          Your alumni.ucla.edu email has been created. Each account includes 5 GB of storage, which is shared across Email, Google Drive, and Google Photos.
        </p>
        <p>
          You can access your Google account <a href="https://accounts.google.com/signin/v2/identifier?continue=https%3A%2F%2Fmail.google.com%2Fmail%2F&service=mail&sacu=1&rip=1&flowName=GlifWebSignIn&flowEntry=ServiceLogin" target="_blank" rel="noopener noreferrer">here</a>. Detailed instructions for activating the account can be <a href="https://drive.google.com/file/d/1vhso_T_rMbvAwx22g_NVdWHk0EGnQDrm/view?usp=sharing" target="_blank" rel="noopener noreferrer">found here</a>. If you’d like to enable the email forwarding feature so that emails will automatically send to another email address, you can do so by following <a href="https://drive.google.com/file/d/1jEnRHyUSgW-PVQocjKCeFrU22wnK2_RS/view" target="_blank" rel="noopener noreferrer">these instructions</a>.
        </p>
        <p>If you would like to change your email address, edit the text field below.</p>

        <Update
          constituentLookupId={crmUser.profile.constituentLookupId}
          email={crmUser.alumniEmails[0]}
        />
      </div>
    </section>

  ) : crmUser.alumniEmails?.length > 1? (
    // many existing email addresses
    <section className={css['alumni-wrapper']}>
      <div className={css.content}>
        <h1 className="h2 black bold with-line line-yellow " id="landingPageTitle">@ALUMNI.UCLA.EDU Email</h1>
        <div className={css.multiple}>
          <p>You have multiple @alumni.ucla.edu email addresses (listed below).  You can access them <a href="https://accounts.google.com/signin/v2/identifier?continue=https%3A%2F%2Fmail.google.com%2Fmail%2F&service=mail&sacu=1&rip=1&flowName=GlifWebSignIn&flowEntry=ServiceLogin" target="_blank" rel="noopener noreferrer">here</a>.  If you'd like to enable the email forwarding feature so that emails will automatically send to another email address, you can do so by following <a href="https://drive.google.com/file/d/1jEnRHyUSgW-PVQocjKCeFrU22wnK2_RS/view" target="_blank" rel="noopener noreferrer">these instructions.</a></p>

          <ul>
            { crmUser.alumniEmails.map((em,idx) => (
              <li key={idx}>{ em }</li>
            ))}
          </ul>

          <p>If you have any questions or need further assistance, please call <a href="tel:3108252586">310-825-2586</a> Monday through Friday between 8 a.m. and 5 p.m. PT or email <a href="mailto:alumni@alumni.ucla.edu">alumni@alumni.ucla.edu</a>.</p>
        </div>
      </div>
    </section>

  ) : (
    // no previously created email address
    <section className={css['alumni-wrapper']}>
      <div className={css.content}>
        <h1 className="h2 black bold with-line line-yellow" id="landingPageTitle">@ALUMNI.UCLA.EDU Email</h1>
        <p>Use the form below to create an @alumni.ucla.edu account and password.  This will be a Gmail account. Each account includes 5 GB of storage, which is shared across Email, Google Drive, and Google Photos.</p>

        <Create
          {...crmUser.profile.primaryName}
          constituentLookupId={crmUser.profile.constituentLookupId}
        />
      </div>
    </section>

  )
}

export default AlumniEmail;
