import IdmReactTable from "common/IdmReactTable"

import { FilledYellow } from 'css/Buttons'

import { QueryResultsStyles, AdminButtonWrap } from 'admin/QueryStyles'
import { formatDate, formatPhone } from 'admin/adminutil'

function DownloadSearchResults(props) {

  const tableProps = {
    initialState: {
      sortBy: [
       {
        id: 'DownloadDate',
        desc: true
       }
      ]
    },
  }

  const mainTableColumns = [
    { Header: 'Date', desc: true, accessor: 'DownloadDate', Cell: cell => formatDate(cell.value)},
    { Header: 'Type', desc: false, accessor: 'ReportType'},
    { Header: 'Year', desc: false, accessor: 'ReportYear'},
    { Header: 'CRM ID', desc: false, accessor: 'CrmId'},
    { Header: 'Username', desc: false, accessor: 'CognitoUsername', Cell: cell =>formatPhone(cell.value) || null},
    { Header: 'File Name', desc: true, accessor: 'FileName'},
    { Header: 'Fund/ID', desc: false, accessor: 'ReportNumber'},
  ]

  const copyTableToClipboard = () => {
    const textToCopy = [mainTableColumns.map(c => c.Header).join('\t')];
    props.data.forEach(row => {
      textToCopy.push(mainTableColumns.map(c => {
        if (c.accessor === 'DownloadDate')
          return formatDate(row.DownloadDate || '')
        else if (c.accessor === 'CrmId')
          return row.CrmId ? "'" + row.CrmId : ''
        else if (c.accessor === 'CognitoUsername')
          return formatPhone(row.CognitoUsername)
        else
          return (row[c.accessor] && row[c.accessor].indexOf('0') === 0) ? "'" + row[c.accessor] : row[c.accessor] || ''
      })
      .join('\t'))
    })
    navigator.clipboard.writeText(textToCopy.join('\n'))
  }

  return (
    <QueryResultsStyles searchType={'downloads'}>
      {props.data.length > 0 &&
        <IdmReactTable
          { ...tableProps }
          data={ props.data }
          columns={ mainTableColumns }
        />}

      <section>
        <div>
          <span>Total Results: </span>{props.data.length}
          {props.data.length > 0 &&
            <AdminButtonWrap>
              <FilledYellow
                width="7.0625rem"
                bgColor={"#FFD100"}
                fontColor={"#005587"}
                borderColor={"#FFD100"}
                name={props.btnName}
                onClick={copyTableToClipboard}
              >
                <span>Copy to Clipboard</span>
              </FilledYellow>
            </AdminButtonWrap>}

        </div>
      </section>
    </QueryResultsStyles>
  )
}

export default DownloadSearchResults
