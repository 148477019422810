import IdmReactTable from "common/IdmReactTable"

import { QueryResultsStyles, AdminButtonWrap } from 'admin/QueryStyles'
import { formatDate, formatPhone } from 'admin/adminutil'
import { FilledYellow } from 'css/Buttons'

function AuditTrailResults(props) {

  const tableProps = {
    initialState: {
      sortBy: [
       {
        id: 'Timestamp',
        desc: true
       }
      ]
    },
  }

  const mainTableColumns = [
    { Header: 'Date', desc: true, accessor: 'Timestamp', Cell: cell => formatDate(cell.value)},
    { Header: 'Action', desc: false, accessor: 'Action' },
    { Header: 'CRM ID', desc: false, accessor: 'CrmId'},
    { Header: 'Username/UCLA1ID', desc: false, name: 'Username', accessor: row => row.CognitoUsername || row.Ucla1Id, Cell: cell => cell.value ? formatPhone(cell.value) : ''},
    { Header: 'Previous ID', desc: false, name: 'PreviousCrmId', accessor: row => row.PreviousCrmId || row.PreviousUcla1Id},
    { Header: 'Admin User', desc: false, accessor: 'AdminUsername' },
  ]

  const copyTableToClipboard = () => {
    const textToCopy = [mainTableColumns.map(c => c.Header).join('\t')];
    props.data.forEach(row => {
      textToCopy.push(mainTableColumns.map(c => {
        if (c.accessor === 'Timestamp')
          return formatDate(row.Timestamp || '');
        else if (c.accessor === 'CrmId')
          return row.CrmId ? "'" + row.CrmId : '';
        else if (c.name === 'Username')
          return formatPhone(row.CognitoUsername || row.Ucla1Id || '');
        else if (c.name === 'PreviousCrmId')
          return row.PreviousCrmId ? "'" + row.PreviousCrmId : row.PreviousUcla1Id || '';
        else
          return row[c.accessor] || '';
      })
      .join('\t'))
    })
    navigator.clipboard.writeText(textToCopy.join('\n'))
  }

  return (
    <QueryResultsStyles searchType={'audit'}>
      {props.data.length > 0 &&
        <IdmReactTable
          { ...tableProps }
          data={ props.data }
          columns={ mainTableColumns }
        />}

      <section>
        <div>
          <span>Total Results: </span>{props.data.length}
          {props.data.length > 0 &&
            <AdminButtonWrap>
              <FilledYellow
                width="7.0625rem"
                bgColor={"#FFD100"}
                fontColor={"#005587"}
                borderColor={"#FFD100"}
                name={props.btnName}
                onClick={copyTableToClipboard}
              >
                <span>Copy to Clipboard</span>
              </FilledYellow>
            </AdminButtonWrap>}

        </div>
      </section>
    </QueryResultsStyles>
  )
}

export default AuditTrailResults
